import React from "react"
import styled from "styled-components"
import HeadShot from "../../assets/images/HeadShot.js"
import { theme, horizontalWhiteGrey, elevation, Container, Para } from "../../styles/index.js"

export default function PostFooter() {
	return (
		<Container modifiers={["section", "block", "full_width"]} background={horizontalWhiteGrey}>
			<ThankYouContainer modifiers={["nested"]}>
				<Para modifiers={["center", "narrow"]}>
					Thank you for taking the time to check out this content! I hope you gained some valuable insight on the topic.
					If you spot any typos or would like to share feedback, send me an email at JG.WebMedia@gmail.com.
				</Para>
			</ThankYouContainer>
			<InnerContainer as="div">
				<HeadShot />
				<CopyWrapper>
					<strong>About the author:</strong>
					<div>
						<Para>
							<strong>James Gleason</strong> is a full-stack JavaScript developer with a passion for design. Areas of
							interest include: React, GraphQL, Node, Gatsby, Next, Figma, Adobe Xd, automation, and how the technology
							can solve problems for people.
						</Para>
					</div>
				</CopyWrapper>
			</InnerContainer>
		</Container>
	)
}

const ThankYouContainer = styled(Container)`
	margin-bottom: 30px;

	p {
		font-size: 1.475rem;
		color: ${theme.black};
	}
`

const InnerContainer = styled(Container)`
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto;
	justify-items: center;
	border-radius: 4px;
	padding: 18px 28px;
	background: ${horizontalWhiteGrey};
	${elevation[2]};

	@media only screen and (min-width: 800px) {
		grid-template-columns: 1fr 4fr;
		grid-template-rows: auto;
		align-items: center;
		max-width: 900px;
	}

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
		max-width: 250px;
		max-height: 250px;
		margin: 0 0 2rem;

		@media only screen and (min-width: 800px) {
			max-width: 125px;
			max-height: 125px;
			margin: 0 2rem 0.5rem 0;
		}
	}
`

const CopyWrapper = styled.div`
	p,
	strong {
		color: ${theme.black};
	}

	div {
		columns: 300px 2;
		font-weight: 500;
	}
`
