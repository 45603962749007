import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PostFooter from "../components/blog/PostEpilogue"
import { SkipToContentBtn, ScrollToTopBtn } from "../lib"
import Head from "./Head"
import BlogNavBar from "./navigation/BlogNavBar"
import Footer from "./footer/Footer"
import Socket from "./footer/Socket"
import "../styles/fonts/index.css"
import "../styles/main.scss"

export default function Layout_main({ children, location }) {
	return (
		<>
			<DocumentHead location={location} />
			<SkipToContentBtn />
			<BlogNavBar location={location} />
			<main id="content">{children}</main>
			<PostFooter />
			<Footer location={location} />
			<Socket />
			<ScrollToTopBtn />
		</>
	)
}

function DocumentHead({ location }) {
	return (
		<StaticQuery
			query={graphql`
				query BLOG_HEAD_QUERY {
					site {
						siteMetadata {
							title
						}
					}
				}
			`}
			render={data => <Head data={data} location={location} />}
		/>
	)
}
