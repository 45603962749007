import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import { elevation } from "../../styles/index.js"

const HEADSHOT_QUERY = graphql`
	{
		headshot: file(relativePath: { regex: "/photos/jamey-headshot.jpg/" }) {
			childImageSharp {
				gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
	}
`

function HeadShot({ width, height, className }) {
	return (
		<StaticQuery
			query={HEADSHOT_QUERY}
			render={data => (
				<StyledImg
					image={data.headshot.childImageSharp.gatsbyImageData}
					alt="Portrait of James Gleason"
					width={width}
					height={height}
					className={className}
				/>
			)}
		/>
	)
}

const StyledImg = styled(GatsbyImage)`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	${elevation[2]};

	img {
		${({ width }) => width && `max-width: ${width}px`};
		${({ height }) => height && `min-height: ${height}px`};
	}
`

export default HeadShot
