import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Hero } from '../../lib'
import { isBrowser } from '../../utils'
import { darkAlt, hoverColor, lighterGrey, Container, elevation } from '../../styles'
import Layout_blog from '../../layouts/Layout_blog'

export const POST_QUERY = graphql`
  query PostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      tableOfContents
      fields {
        slug
      }
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        line1
        line2
        line3
        image
        image_alt
        hero_bg
      }
    }
  }
`

export default function Template_post({ location, data: { mdx } }) {
  const { body, frontmatter } = mdx

  const codeNodes = isBrowser ? Array.from(document.querySelectorAll('code')) : []

  function copyCode(node) {
    // Get innerText of code DOM node that was clicked
    const code = node.innerText
    // Create a hidden temporary text input with the value of the innerText
    const tmpInputElm = document && document.createElement('input')
    tmpInputElm.type = 'text'
    tmpInputElm.value = code
    // Append the text input to the document body
    document && document.body.appendChild(tmpInputElm)
    // Select the text from the input
    tmpInputElm.select()
    // Copy the text to clipboard
    document && document.execCommand('Copy')
    // Remove the text input
    document && document.body.removeChild(tmpInputElm)
  }

  codeNodes.forEach((node) => node.addEventListener('click', () => copyCode(node)))

  return (
    <Layout_blog location={location}>
      <Hero
        noBtn
        line1={frontmatter.line1}
        line2={frontmatter.line2}
        line3={frontmatter.line3}
        image={frontmatter.hero_bg}
        height={400}
      />
      <PageContentWrapper>
        <ArticleContainer
          image={frontmatter.image}
          modifiers={['section', 'nested', 'no_top_pad']}
        >
          {frontmatter.image && (
            <img src={frontmatter.image} alt={frontmatter.image_alt} />
          )}
          <article>
            <MDXRenderer>{body}</MDXRenderer>
          </article>
        </ArticleContainer>
      </PageContentWrapper>
    </Layout_blog>
  )
}

const PageContentWrapper = styled.section`
  position: relative;
`

const ArticleContainer = styled(Container)`
  & {
    max-width: 775px;
  }

  h2 {
    margin-top: 2.875rem;
    margin-bottom: 0.875rem;
  }

  h3 {
    margin-top: 0.875rem;
  }

  img,
  pre,
  code {
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 100%;
    margin-top: 2.375rem;
    margin-bottom: 2.375rem;
  }

  ${({ image }) =>
    image &&
    `img:nth-of-type(1) {
        position: relative;
        left: 50%;
        object-fit: cover;
        transform: translate(-50%, -85px);
        margin-bottom: -85px;
        border-radius: 8px;
        padding: 0px;
        max-width: 93vw;
        ${elevation[3]}
      }`};

  a {
    transition: all 250ms ease;
    &:hover {
      color: ${hoverColor};
    }
  }

  blockquote,
  dl,
  ol,
  p,
  pre,
  table,
  ul {
    margin-bottom: 16px;
    margin-top: 0;
  }

  table {
    display: block;
    overflow: auto;
    width: 100%;
  }

  table th {
    font-weight: 600;
  }

  table td,
  table th {
    border: 1px solid #dfe2e5;
    padding: 6px 13px;
  }

  table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }

  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  blockquote {
    border-left: solid 3px ${lighterGrey};
    padding-left: 0.8rem;
    margin-top: 2.375rem;
    margin-bottom: 2.375rem;
  }

  ul,
  ol {
    padding-left: 3rem;
    margin-top: 0.95em;
    margin-bottom: 0.95em;
  }

  pre {
    position: relative;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 44px 32px 18px;
    background: ${lighterGrey};
    border-radius: 4px;
    line-height: 1.66;
    overflow-x: scroll;
  }

  code {
    background: ${lighterGrey};
    border-radius: 4px;
    padding: 2px 4px;
    border-radius: 4px;
  }
  pre > code {
    padding: 0px;
    display: inline-block;
    width: 100%;
    max-width: 100%;
  }
  pre > code.language-cmd {
    padding: 0px;
    white-space: normal;
  }
  code.language-js::before,
  code.language-json::before,
  code.language-html::before,
  code.language-css::before,
  code.language-cmd::before {
    font-size: 1rem;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: ${darkAlt};
    border-radius: 4px 4px 0 0;
    padding: 0;
    text-indent: 15px;
  }
  code.language-js::before {
    content: 'JS';
  }
  code.language-json::before {
    content: 'JSON';
  }
  code.language-html::before {
    content: 'HTML';
  }
  code.language-css::before {
    content: 'CSS';
  }
  code.language-cmd::before {
    content: 'COMMAND LINE';
  }

  iframe {
    margin: 0px auto;
    @media only screen and (min-width: 800px) {
      margin: 80px auto;
    }
  }
`
